<template>
  <NavToolsProvider>
    <div class="flex h-screen overflow-hidden">
      <NavSidebar :menu-width="menuWidth" v-if="isLargeScreen" @update:sidebar-open="handleSidebarToggle" class="fixed z-40 h-screen transition-all duration-300" :class="sidebarOpen ? 'w-64' : 'w-20'" />
      <div :class="[contentClass, isLargeScreen ? '' : '!pl-0']" class="relative flex grow flex-col overflow-y-auto bg-arco-dbg bg-navbg bg-[length:420px_370px] bg-left-top bg-no-repeat">
        <NavHeader />

        <div class="flex grow flex-col px-4">
          <NavBreadcrumbs />
          <router-view v-slot="{ Component, route }">
            <transition name="slide-right" mode="out-in">
              <div class="flex-1" :key="route.name">
                <component :is="Component" />
              </div>
            </transition>
          </router-view>
        </div>
        <BaseLocked class="absolute inset-0 z-30" />
      </div>
    </div>
  </NavToolsProvider>
</template>

<script setup>
import NavToolsProvider from "@/components/Nav/NavToolsProvider.vue";
import { ref, onMounted, onUnmounted, computed, watch, watchEffect } from "vue";
import BaseLocked from "@/components/Base/BaseLocked.vue";
import NavSidebar from "@/components/Nav/NavSidebar.vue";
import NavHeader from "@/components/Nav/NavHeader.vue";
import NavBreadcrumbs from "@/components/Nav/NavBreadcrumbs.vue";
import { useStructureStore } from "@/Stores/structure";
import { useStore } from "vuex";
import { useSubscription } from "@/api";
import axios from "axios";
import { useRouter } from "vue-router";
import { inject } from "vue";
import { jwtParser } from "@/composables/jwtParser";
import sessionSubs from "@/graphql/user/subscription_session.gql";
const vuexStore = useStore();
const store = useStructureStore();
const sidebarOpen = ref(false);
const router = useRouter();
const parsedJwt = jwtParser();
const authEndpoint = inject("authEndpoint");
const userSessionData = ref(null);
const userSubscription = computed(() => vuexStore.getters["tenant/subscription"]);
const { data, error, isLoading } = useSubscription(sessionSubs, "user");

const transformedData = computed(() => {
  if (!data.value) return [];
  return data.value[0]?.active_session || null;
});

// const isModuleLocked = computed(() => {
//   const routeName = router.currentRoute.value.name;
//   if (!userSubscription.value || !userSubscription.value.modules) {
//     return null;
//   }

//   let moduleOrFeatureExists = false;

//   const hasPerformanceDashboard = userSubscription.value.modules.some((module) => {
//     if (module.features) {
//       const feature = module.features.find((feature) => feature.name === "PerformanceDashboard");
//       if (feature) {
//         return true
//       }
//     }
//   });

//   if (hasPerformanceDashboard && routeName === 'MetricsHome') {
//     return true;
//   }

//   userSubscription?.value.modules?.some((module) => {
//     if (module.route === routeName) {
//       moduleOrFeatureExists = true;
//       return true;
//     }

//     if (module.features) {
//       const feature = module.features.find((feature) => feature.name === routeName);
//       if (feature) {
//         moduleOrFeatureExists = true;
//         return true;
//       }
//     }

//     return false;
//   });

//   return moduleOrFeatureExists ? true : "not-found";
// });

// watchEffect(() => {
//   if (userSubscription.value?.modules) {
//     const currentPath = router.currentRoute.value.path;
//     if (isModuleLocked.value == "not-found" && currentPath !== "/") {
//       router.push({ name: "NotFound" });
//     }
//   }
// });

const handleSidebarToggle = (isOpen) => {
  sidebarOpen.value = isOpen;
};
let windowWidth = ref(0);
let menuWidth = ref("80px");
const contentClass = computed(() => ({
  "pl-64": sidebarOpen.value,
  "pl-20": !sidebarOpen.value,
  "transition-all": true,
  "duration-300": true,
}));
const isLargeScreen = computed(() => windowWidth.value > 640);

watch(
  () => transformedData,
  (newValue) => {
    checkSession();
  },
  { deep: true }
);

async function checkSession() {
  const sessionIdentifier = parsedJwt?.session_identifier;
  if (sessionIdentifier) {
    try {
      const userSession = await axios.post(
        `${authEndpoint}/verify-session`,
        {
          userId: parsedJwt["x-hasura-user-id"],
          sessionIdentifier,
        },
        { withCredentials: true }
      );

      if (!userSession.data.valid) {
        localStorage.removeItem("jwt_expires");
        localStorage.removeItem("jwt_token");
        router.push({
          path: "/auth",
          query: { redirect: router.currentRoute.value.fullPath },
        });
        return;
      }
    } catch (error) {
      console.error("Error verifying session");
      router.push({
        path: "/auth",
        query: { redirect: router.currentRoute.value.fullPath },
      });
      return;
    }
  }
}

onMounted(() => {
  vuexStore.dispatch("tenant/getTenantData");
  window.addEventListener("resize", handleResize);
  handleResize();
  store.getStructure();
  vuexStore.dispatch("structure/getStructure");
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

function handleResize() {
  windowWidth.value = window.innerWidth;
}
</script>

<style scoped>
.nav-width {
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.2s;
}

.slide-left-enter-from {
  opacity: 0;
  transform: translate(30px, 0);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translate(-30px, 0);
}

.slide-right-enter-from {
  opacity: 0;
  transform: translate(-50px, 0);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translate(50px, 0);
}

.transition-padding,
.nav-sidebar-transition {
  transition: transform 0.3s ease;
}
</style>
